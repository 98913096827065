@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Sofia+Sans:wght@300;400;600&display=swap');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

img {
	object-fit: contain;
}

button {
	cursor: pointer;
	border: none;
}

.none-media {
	opacity: 0;
	height: 1px;
	width: 1px;
	position: absolute;
	z-index: -99999;
	bottom: 0;
	display: none;
}

body {
	overflow: hidden;
	position: fixed;
	width: 100%;
	height: 100%;
	background: #ffffff;
}

#root {
	width: 100%;
	height: 100%;
}

.wrapper {
	overflow: scroll;
	width: 100%;
	height: 100%;
}

.blushed-footer {
	/* position: absolute; */
	/* bottom: 0; */
}
.blushed-bg {
	width: 100%;
	/* height: auto; /1* background-size: cover; *1/ */
	/* position: absolute; */
	/* backgroundposition: 'center'; */
	/* backgroundsize: 'cover'; */
	/* backgroundrepeat: 'no-repeat'; */
	/* width: '100vw'; */
	/* height: '100vh'; */
}

.policy-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.line {
	margin-right: 40px;
	width: 50%;
	height: 2px;
	background: #ff60ac;
}

.policy-item {
	margin: 0 10px;
	color: #462e4c;
	font-family: Sofia Sans;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}
